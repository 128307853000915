import React, { useState, useEffect } from "react";
import $ from "jquery";

function PaymentMethod() {

    const [showCoupon, setShowCoupon] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setShowCoupon(event.target.checked);
    }

    // const [showPaymentForm, setShowPaymentForm] = useState(false);

    const [showMobileMoneyField, setShowMobileMoneyField] = useState(true);

    useEffect(() => {
        // const paymentMethodInputs = document.querySelectorAll('input[name="payment_type"]');

        // Hide duplicate email input[name=email]
        // document.querySelector('input[name="payment_type"]#visa_payment').addEventListener("change", function(){
        //     console.log(this)
        //     const emailInputs = document.querySelectorAll('form input[name="email"]');
        //     // document.querySelectorAll(".payment_info_1")[0].style.display = "none";
        //     if(emailInputs.length > 1){
        //         emailInputs[1].parentNode.style.display = "none";
        //     }
        // })
        $('input[name="payment_type"]').change(function(){
            if($(this).val() === "visa"){
                const emailInputs = document.querySelectorAll('form input[name="email"]');
                // document.querySelectorAll(".payment_info_1")[0].style.display = "none";
                if(emailInputs.length > 1){
                    emailInputs[1].parentNode.style.display = "none";
                }

                $(".payment_info_1").hide();
                $(".payment_info_1 input").prop("required", false);

            }else{

                $(".payment_info_1").show();
                $(".payment_info_1 input").prop("required", true);
            }
        })
    }, []);

    return (
        <>
            <div className="row mb-4 align-items-center" id="payment_method_div">
                <h4 className="card-title text-center mb-3">Payment Method</h4>

                <div className="col">
                    <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                        <input className="form-check-input" checked={showMobileMoneyField} onChange={() => setShowMobileMoneyField(true)} type="radio" id="mobile_money" value="mobile_money" name="payment_type" required/>
                        <label className="form-check-label" htmlFor="mobile_money">Mobile Money</label>
                    </div>
                </div>

                <div className="col">
                    <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                        <input className="form-check-input" checked={!showMobileMoneyField} onChange={() => setShowMobileMoneyField(false)} type="radio" id="visa_payment" value="visa" name="payment_type" required/>
                        <label className="form-check-label" htmlFor="visa_payment">VISA Payments</label>
                    </div>
                </div>

            </div>

            <div className="payment_form">

                { showMobileMoneyField &&
                    <div className="mb-3 collection_msisdn_div">
                        <label htmlFor="collection_msisdn" className="form-label">Paying Phone Number</label>
                        <input type="text" className="form-control" name="collection_msisdn" id="collection_msisdn" placeholder="Enter paying phone number" required/>
                    </div>
                }

                { !showMobileMoneyField &&
                    <>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" name="name" id="name" placeholder="Enter name" required/>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email Address</label>
                            <input type="email" className="form-control" name="email" id="email" placeholder="Enter email address" required/>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone_number" className="form-label">Phone Number</label>
                            <input type="text" className="form-control" name="phone_number" id="phone_number" placeholder="Enter phone" required/>
                        </div>
                    </>
                }
            </div>

            <div className="mb-3 form-check">
                <input className="form-check-input" type="checkbox" id="coupon_check" name="has_coupon" checked={isChecked} onChange={handleCheckboxChange}/>
                <label className="form-check-label" htmlFor="coupon_check">
                    Click if you have a coupon.
                </label>
            </div>

            {showCoupon && 
                <div className="mb-3 coupon_check">
                    <label htmlFor="coupon" className="form-label">Coupon</label>
                    <input type="text" className="form-control" name="coupon" id="coupon" placeholder="Enter coupon" disabled={!isChecked} required/>
                </div>
            }
        </>
    )
}

export default PaymentMethod;