import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

import $ from 'jquery';
import { api_root } from "./api";
import Swal from "sweetalert2";

function SelectEvents(){

    function get_events(){
        var xhr = null;

        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })
        
        if(xhr){
            return;
        }
        xhr = $.ajax({
            method: 'GET',
            url: api_root()+'/valid_events/',
            dataType: 'json',

            success: function(json) {

                Swal.close();
                if(json.error){
                    Swal.fire({
                        title: "No Events Found",
                        icon: "error",
                        html: '<div class="text-danger m-1"> <i class="fa fa-error-cirle"></i> <br/> No Events Found </div>',
                        showConfirmButton: false,
                        allowOutsideClick: !0
                    })
                    return;
                }

                setItems(json.events);
                
            },
            error: function() {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    html: 'An error occured',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })
            }
        });
    }

    const [items, setItems] = useState([]);

    useEffect(() => {
        get_events();
    }, []);

    return (
        <>
            <Header />
            <div className="m-5">

                {items.map((item) => (

                    <div key={item.id} className="col">
                        <Link className="card bg-custom" to={`/buy_ticket?id=${item.id}`}>
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">
                                            {item.event_name}
                                        </h4>
                                        <div className="d-flex justify-content-between">
                                            <span className="text-white">Venue : {item.venue}</span>
                                            <span className="text-white">&nbsp;</span>
                                            <span className="text-white">Start Date : {item.start_date} {item.start_time}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                
                ))}

            </div>
            <Footer />
        </>
    )
}

export default SelectEvents;