//components/RiceCart.js

import React from 'react';

function RiceCart({cartCourses, deleteCourseFromCartFunction, totalAmountCalculationFunction, setCartCourses, }) {

    return (
        <div className={`cart ${cartCourses.length > 0 ? 'active' : ''}`}>
            <h3 className='card-title text-center'>Your Cart</h3>
            <p className='text-primary'>Delivery around Kampala: Kasangati, Kiwatule, Wakiso, Kireka and Kibuye</p>
            {cartCourses.length === 0 ? (
                <h3 className='text-center'>Your cart is empty.</h3>
            ) : (
                <>
                    {cartCourses.map((item) => (
                        <div key={item.product.id} className='mb-3'>
                            <div className="">Item : {item.product.name} <span className='float-end'>Sub Total : {item.product.price * item.quantity}</span></div>
                            <div className="d-flex justify-content-between align-items-center">
                                <input name="rice_product[]" value={item.product.id} type="hidden" />
                                <button className="btn btn-custom text-white mx-1" type="button" onClick={(e) => {
                                    setCartCourses((prevCartCourses) => {
                                        const updatedCart = prevCartCourses.map(
                                            (prevItem) =>
                                                prevItem.product.id === item.product.id
                                                    ? { ...prevItem, quantity:
                                                            Math.max(item.quantity - 1, 0) }
                                                    : prevItem
                                        );
                                        return updatedCart;
                                    })
                                }}>-</button>
                                <div className="input-group">
                                    <input type="number" name="rice_qty[]" className="form-control" min="1" value={item.quantity}/>
                                </div>
                                <button className="btn btn-custom text-white mx-1" type="button" onClick={(e) => {
                                    setCartCourses((prevCartCourses) => {
                                        const updatedCart = prevCartCourses.map(
                                            (prevItem) =>
                                                prevItem.product.id === item.product.id
                                                    ? { ...prevItem, quantity:
                                                            item.quantity + 1 }
                                                    : prevItem
                                        );
                                        return updatedCart;
                                    })
                                }}>+</button>
                                <button type="button" className="btn btn-dark" onClick={() => deleteCourseFromCartFunction(item)}>X</button>
                            </div>
                            <p className='text-dark text-center'>Enter Number of bags</p>
                        </div>
                    ))}
                    <div className="row my-2">
                        <div className='col'>
                            <div className="text-center">
                                <p className="card-title">Total Amount : {totalAmountCalculationFunction()} /=
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}

export default RiceCart;
