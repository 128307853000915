import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import PaymentMethod from "./PaymentMethod";
import TransactionType from "./TransactionType";

import {api_root, check_status, sample_function} from './api';
import $ from "jquery";
import Swal from "sweetalert2";

import { getCartTotalAmount, getCartTotalQuantity, clearCart, deleteCartItem, setCart, getCart } from "./cartFunctions"; // Import your cart functions

function CheckoutPage() {
    const [cartItems, setCartItems] = useState(getCart() || []);

    const [totalTickets, setTotalTickets] = useState(getCartTotalQuantity());
    const [totalAmount, setTotalAmount] = useState(getCartTotalAmount());


    const decreaseQuantity = (index) => {
        const updatedCart = [...cartItems];
        if (updatedCart[index].quantity > 1) {
            updatedCart[index].quantity--;
            setCart(updatedCart);
            setCartItems(getCart());
        }
    };

    const increaseQuantity = (index) => {
        const updatedCart = [...cartItems];
        if (updatedCart[index].quantity < updatedCart[index].package.available_tickets) {
            updatedCart[index].quantity++;
            setCart(updatedCart);
            setCartItems(getCart());
        }
    };

    const removeItem = (pkg_id) => {
        if (pkg_id) {
            deleteCartItem(pkg_id)
            setCartItems(getCart())
        }
    };


    const [showPaymentFields, setPaymentFields] = useState(false);

    const handlePaymentTypeChange = () => {
        if ($("#visa_payment").val() === 'visa') {
            setPaymentFields(true);
        } else {
            setPaymentFields(false);
        }
    };

    const clearCartHandler = () => {
        clearCart()
        setCartItems(getCart())
    };

    useEffect(() => {
        setTotalTickets(getCartTotalQuantity());
        setTotalAmount(getCartTotalAmount());
        handlePaymentTypeChange();
    }, [cartItems]);


    const buyTicketNowForm = (e) =>{

        e.preventDefault();

        $("input[name=transaction]").attr({'disabled':true})

        var form_data = $("#buy_ticket_now_form").serialize();

        let output = '';
        const regex = /([^:]+):([^|]+)/g;

        sample_function().then(function(result) {
            result.replace(regex, (match, col1, col2) => {
                output += `<tr><td>${col1.replace(/\|\|/g, "").trim()}</td><td>${col2.trim()}</td></tr>`;

                var htmlMessage = `<div style="font-size: 14px !important;"> ${output} </div>`;

                Swal.fire({
                    icon: "question",
                    title: "Confirm Transaction",
                    html: `<table style="font-size: 14px !important; text-align: left;" width="100%">${htmlMessage}</table>`,
                    confirmButtonText: "Confirm",
                    confirmButtonColor: "#cc3432",
                    showDenyButton: true,
                    denyButtonText: 'No',
                    denyButtonColor: 'grey',
                    allowOutsideClick: !1,
                }).then((result) => {

                    if (result.isConfirmed) {

                        Swal.fire({
                            title: "Processing",
                            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                            allowOutsideClick: !1,
                            showConfirmButton: false,
                        });

                        $.ajax({
                            method: "POST",
                            url: api_root()+"/events_buy_ticket/",
                            data : form_data,
                            success: function(res){
                                if (res.error){
                                    Swal.fire({
                                        icon: "error",
                                        title: res.message,
                                        confirmButtonColor: "#cc3432",
                                        allowOutsideClick: !0
                                    })
                                    return
                                }

                                Swal.fire({
                                    html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/> Just a Moment',
                                    title: "Hold On",
                                    showConfirmButton: false,
                                    allowOutsideClick: !1
                                })

                                if(!res.reference){
                                    // redirect
                                    $(".visa_redirect").html(res.message)
                                    // res.message
                                }

                                if (res.reference){
                                    Swal.fire({
                                        html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/>'+res.message,
                                        title: "Please wait",
                                        showConfirmButton: false,
                                        allowOutsideClick: !1
                                    })
                                    check_status(res.reference[0], null, true, 3000, clearCartHandler)
                                } 
                            }, error: function(){
                                Swal.close();
                                Swal.fire({
                                    icon: "error",
                                    title: "An Error Occurred",
                                    confirmButtonColor: "#cc3432",
                                    allowOutsideClick: !0
                                })
                            }
                        })

                    }
                })
            })
        })

    }


    return (

        <>
        <Header />
        <form className="row mx-4 mt-4" id="buy_ticket_now_form" onSubmit={buyTicketNowForm}>
            <div className="col-md-6">
                <div className="">
                    <div className="card-body">
                        <h5 className="card-title text-center font-size-16 mb-3">Cart <span id="tickets_no" className="d-none">{totalTickets}</span> </h5>
                        {cartItems.map((cartItem, index) => (
                            <div className="card" key={index}>
                                <div className="card-body">
                                    <div className="card-title">Ticket : {cartItem.package.package_name}</div>
                                    <div className="card-title">Sub Total : {cartItem.package.amount * cartItem.quantity}</div>
                                    <div className="d-flex justify-content-between align-items-center">
                                    <input name="ticket_pkg[]" value={cartItem.package.id} type="hidden" />
                                        <button
                                            className="btn btn-custom text-white mx-1"
                                            type="button"
                                            onClick={() => decreaseQuantity(index)}
                                        >
                                            -
                                        </button>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                name="ticket_qty[]"
                                                readOnly
                                                className="form-control"
                                                min="1"
                                                value={cartItem.quantity}
                                                max={cartItem.package.available_tickets}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-custom text-white mx-1"
                                            type="button"
                                            onClick={() => increaseQuantity(index)}
                                        >
                                            +
                                        </button>
                                        <button
                                            type="button" 
                                            className="btn btn-dark"
                                            onClick={() => removeItem(cartItem.package.id)}
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Tickets: {totalTickets}</h5>
                            <h5>Amount: {totalAmount} /=</h5>
                        </div>
                        <div className="mt-3 d-flex justify-content-between">
                            <button className="btn btn-dark" type="button" onClick={() => clearCartHandler()}>
                                Clear Cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        {showPaymentFields && <h4 className="card-title text-center payment_info_1">Payment Info</h4> }
                        <div className="m-3">
                                
                                <TransactionType transaction="buy_ticket" />
                                <input type="hidden" className="form-control" name="amount" value={totalAmount} id="amount" placeholder="Enter amount (ugx)" required/>

                                {showPaymentFields && 
                                <div className="payment_info_1">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input type="text" className="form-control" name="user_name" id="user_name" placeholder="Enter name" required/>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email Address</label>
                                        <input type="email" className="form-control" name="user_email" id="user_email" placeholder="Enter email address"/>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                        <input type="text" className="form-control" name="user_phone_number" id="user_phone_number" placeholder="Enter phone" required/>
                                    </div>
                                </div>
                                }

                                <PaymentMethod />

                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <Footer />
        </>
    );
}

export default CheckoutPage;
