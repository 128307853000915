import React from "react";
import { useNavigate } from "react-router-dom";

function CanGoBack() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        // Navigate back to the previous page
        navigate(-1);
    };


    return (
        <button className="btn btn-danger btn-custom waves-effect waves-light btn-rounded back_button"  onClick={handleGoBack}><i className="fa fa-arrow-left"></i></button>
    );
}

export default CanGoBack;
