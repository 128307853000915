import React from "react";
import { Link } from "react-router-dom"

function ContactDetails(){
    return (
        <>
            <h4 className="card-title font-size-16 mb-3">Contact Info</h4>
            <p>
                <span className="fas fa-phone-alt" aria-hidden="true"></span> Phone : <Link to="tel:+256393193345"> +256-393-193-345</Link>
            </p>
            <p>
                <span className="fas fa-phone-alt" aria-hidden="true"></span> Phone : <Link to="tel:+256414664984"> +256-414-664-984</Link>
            </p>
            <p>
                <span className="fas fa-envelope" aria-hidden="true"></span> Email : <Link to="mailto:info@1stop.co.ug"> info@1stop.co.ug</Link>
            </p>
            <p>
            <span className="fas fa-map-marker" aria-hidden="true"></span> Address : Crown House, Plot 181, 1st Floor,Suite 2, Wandegeya-Kubiri Bombo Road Kampala Uganda
            </p>
        </>
    )
}

export default ContactDetails