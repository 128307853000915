import React from "react";
import ContactDetails from "./ContactDetails";
import Footer from "./Footer";
import Header from "./Header";
import $ from 'jquery'
import Swal from "sweetalert2";
import { api_root } from "./api";

function ContactUs(){

    const submit_contact_form = (e) =>{

        e.preventDefault();

        var form_data = $("#contact_form_id").serialize();

        Swal.fire({
            title: "Processing",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            allowOutsideClick: !1,
            showConfirmButton: false,
        });

        $.ajax({
            method: "POST",
            url: api_root()+"/send_contact_us/",
            data : form_data,
            success: function(res){
                Swal.fire({
                    icon: "success",
                    title: "Successfully sent",
                    confirmButtonText: "Close",
                    html: res.message,
                    confirmButtonColor: "#cc3432",
                    allowOutsideClick: !0,
                })
            }, error: function(){
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "An Error Occured",
                    confirmButtonColor: "#cc3432",
                    allowOutsideClick: !0
                })
            }
        })

    }

    return(
        <>
            <Header />
            <div className="row mt-4 mx-4">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title font-size-16 mb-3">Leave a Message</h5>
                            <form method="post" id="contact_form_id" onSubmit={submit_contact_form}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="commentname-input" className="form-label">Full Name</label>
                                            <input type="text" className="form-control" id="commentname-input" name="fullname" placeholder="Enter name" required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="commentphone-input" className="form-label">Phone</label>
                                            <input type="number" className="form-control" id="commentphone-input"  name="phone" placeholder="Enter phone" required/>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="commentemail-input" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="commentemail-input" name="email" placeholder="Enter email"/>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="commentemail-input" className="form-label">Product</label>
                                            <select className="form-control" name="product" id="inputproduct" required>
                                                <option value="">Choose</option>
                                                <option value="Buy Airtime">Buy Airtime</option>
                                                <option value="Transfer Mobile Money">Transfer Mobile Money</option>
                                                <option value="Buy Data Bundles">Buy Data Bundles</option>
                                                <option value="ENHAS Handling Payment">ENHAS Handling Payment</option>
                                                <option value="URA / KCCA Payment">URA / KCCA Payment</option>
                                                <option value="Pay NWSC water">Pay NWSC water</option>
                                                <option value="Electricity">Electricity</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="commentmessage-input" className="form-label">Message</label>
                                    <textarea className="form-control" id="commentmessage-input" name="message" placeholder="Your message..." rows="3" required></textarea>
                                </div>

                                <div className="text-left">
                                    <button type="submit" className="btn btn-custom text-white w-sm">Submit</button>&nbsp;&nbsp;
                                    <button type="reset" className="btn btn-dark w-sm">Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <ContactDetails />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-4">
                <div className="card">
                    <div className="card-body">
                    <iframe
                        style={{ width: "100%", height: "200px" }}
                        src="https://maps.google.com/maps?width=100%25&amp;height=1000&amp;hl=en&amp;q=+(Raffsoft%20Limited)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        aria-label="Raffsoft Limited"
                        frameBorder="0"
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ContactUs;