import React from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit } from './api';
import Header from "./Header";
import Footer from "./Footer";
import TransactionType from "./TransactionType";

function TransferMM(){
    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Transfer Mobile Money</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                <TransactionType transaction="send_money" />
                                <div className="mb-3">
                                    <label htmlFor="recipient_msisdn" className="form-label">Receiving Number</label>
                                    <input type="number" className="form-control" name="recipient_msisdn" id="recipient_msisdn" placeholder="Enter phone" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">Amount (UGX)</label>
                                    <input type="number" className="form-control" name="amount" id="amount" placeholder="Enter amount (ugx)" required/>
                                </div>

                                <PaymentMethod />
                                
                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TransferMM;