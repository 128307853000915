import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Swal from 'sweetalert2';
import { api_root } from "./api";
import $ from "jquery";

function Home(){

    function get_active_services(){
        const sessionData = sessionStorage.getItem('information_required');
        var response = null;
        if (sessionData) {
            const data = JSON.parse(sessionData);
            setServices(data.services);
        }else{
            Swal.fire({
                title: "Loading",
                html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                showConfirmButton: false,
                allowOutsideClick: !1
            })

            const fetchData = async () => {
                if(response){
                    return;
                }
                response = await fetch(api_root()+'/information_required/');
                const data = await response.json();
                sessionStorage.setItem('information_required', JSON.stringify(data));
                setServices(data.services);
            };
            fetchData();
        }
    }

    const [services, setServices] = useState([]);
    $(".back_button").hide()

    useEffect(() => {
        get_active_services()

    }, []);

    if(!services){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })
    }

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="row">

                {services.includes("MOBILEMONEY") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/transfer_mm">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Transfer <br/> Money</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                {services.includes("AIRTIME") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/buy_airtime">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Buy <br/> Airtime</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                {services.includes("DATA") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/buy_data">
                        <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Buy <br/>Data</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                <div className="col-6">
                    <Link className="card bg-custom" to="https://cars.1stop.co.ug">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white">Buy <br/> Cars</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-6">
                    <Link className="card bg-custom" to="https://property.1stop.co.ug">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white">Real <br/> Estate</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-6">
                    <Link className="card bg-custom" to="https://colabo.raffsoft.com/b/signin">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white">Online <br/> Meetings</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-6">
                    <Link className="card bg-custom" to="/events">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white">Buy <br/> Ticket</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-6">
                    <Link className="card bg-custom" to="https://store.1stop.co.ug/">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white"><span style={{fontSize: '16px'}}>Rice / Beans </span><br/> Store</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-6">
                    <Link className="card bg-custom" to="/crowdfunding">
                        <div className="card-body">
                            <div className="d-flex text-center">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 text-white">Crowd <br/> Funding</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {services.includes("NWSC") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/pay_water">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Pay <br/> Water</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                {services.includes("electricity") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/electricity">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Pay <br/> UMEME</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                <div className="col-6">
                    <Link className="card bg-custom" to="/select_gov_payment">
                        <div className="card-body">
                            <div className="text-center">
                                <div className="flex-grow-1" style={{ maxWidth: '100%', whiteSpace: 'nowrap', }}>
                                    <h4 className="mb-0 text-white"><span style={{fontSize: '16px'}}> URA,KCCA </span> <br/> Permit</h4>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

                {services.includes("NAS") && (
                    <div className="col-6">
                        <Link className="card bg-custom" to="/enhas">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Pay <br/>ENHAS</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;