import React, { useEffect } from "react";
import $ from 'jquery';


function TransactionType(props){

    function get_crsf(){
        var csrf = $("input[name=csrfmiddlewaretoken]").val();
        $("#crsf_form_input").val(csrf);
    }

    useEffect(() => {
        get_crsf();
    }, []);

    return (
        <>
            <input type="hidden" name="transaction" value={ props.transaction } required id="id_transaction_0"/>
            <input type="hidden" name="csrfmiddlewaretoken" id="crsf_form_input" />
        </>
    )
}

export default TransactionType;