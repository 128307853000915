//App.js

import React, {useEffect, useState} from 'react';
import RiceCart from './RiceCart';
import {api_root, handleFormSubmit} from "./api";
import Header from "./Header";
import Footer from "./Footer";
import ShowProductComponent from "./ShowProductComponent";
import Swal from "sweetalert2";
import PaymentMethod from "./PaymentMethod";
import TransactionType from "./TransactionType";
import $ from "jquery";

function App() {
    const [courses, setCourses] = useState([]);
    const [storeLocations, setStoreLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');

    useEffect(() => {
        // Fetch store locations on component mount
        const fetchStoreLocations = () => {
            // Make AJAX request to fetch store locations
            $.ajax({
                url: api_root() + '/store/food/locations/',
                method: 'GET',
                dataType: 'json',
                success: function(data) {
                    setStoreLocations(data);
                },
                error: function(xhr, status, error) {
                    console.error('Error fetching store locations:', error);
                }
            });
        };

        fetchStoreLocations();

        const handleLocationChange = (event) => {
            setSelectedLocation(event.target.value);
        };

        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })
        fetch(api_root()+'/get_all_rice_products/')
            .then(response => {

                if (!response.ok) {
                    Swal.close();
                    Swal.fire({
                        title: "Failed",
                        icon: "error",
                        html: 'Error Occurred',
                        showConfirmButton: false,
                        allowOutsideClick: !0
                    })
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                Swal.close();
                setCourses(data); // Assuming the data is an array of course objects
            })
            .catch(error => {
                Swal.close();
                Swal.fire({
                    title: "Failed",
                    icon: "error",
                    html: 'Error Occurred',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })
            });
    }, []);

    const [cartCourses, setCartCourses] = useState([]);
    const [searchCourse, setSearchCourse] = useState('');

    const addCourseToCartFunction = (GFGcourse) => {
        const alreadyCourses = cartCourses
            .find(item => item.product.id === GFGcourse.id);
        if (alreadyCourses) {
            const latestCartUpdate = cartCourses.map(item =>
                item.product.id === GFGcourse.id ? {
                        ...item, quantity: item.quantity + 1 }
                    : item
            );
            setCartCourses(latestCartUpdate);
        } else {
            setCartCourses([...cartCourses, {product: GFGcourse, quantity: 1}]);
        }
    };

    const deleteCourseFromCartFunction = (item) => {
        const updatedCart = cartCourses.filter(cartItem => cartItem.product.id !== item.product.id);
        setCartCourses(updatedCart);
    };


    const totalAmountCalculationFunction = () => {
        return cartCourses
            .reduce((total, item) =>
                total + item.product.price * item.quantity, 0);
    };

    const courseSearchUserFunction = (event) => {
        setSearchCourse(event.target.value);
    };

    const filterCourseFunction = courses.filter((course) =>
        course.name.toLowerCase().includes(searchCourse.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className="row mx-4 mt-4">
                <div className="card">
                    <div className="card-body">
                        <div className='card-title text-center'>Rice / Beans Store</div>
                        <form className="row" id="form_id_main" method='post' onSubmit={handleFormSubmit}>
                            <TransactionType transaction="buy_rice" />
                            <div className='col-md-12 row'>
                                <ShowProductComponent
                                    courses={courses}
                                    filterCourseFunction={filterCourseFunction}
                                    addCourseToCartFunction={addCourseToCartFunction}
                                />
                            </div>

                            <div className='col-md-12'>
                                <RiceCart
                                    cartCourses={cartCourses}
                                    deleteCourseFromCartFunction={deleteCourseFromCartFunction}
                                    totalAmountCalculationFunction={
                                        totalAmountCalculationFunction
                                    }
                                    setCartCourses={setCartCourses}
                                />
                            </div>

                            <PaymentMethod />

                            <div className="mb-3 store_location">
                                <label htmlFor="store_location" className="form-label">Store Location</label>
                                <select className="form-select" name="store_location" id="store_location" required>
                                    <option value="">Select Store Location</option>
                                    {storeLocations.map(location => (
                                        <option key={location.id} value={location.id}>{location.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3 location_description">
                                <label htmlFor="location_description" className="form-label">Location Description</label>
                                <textarea className="form-control" name="location_description" id="location_description" placeholder="Enter Location Description" required/>
                            </div>

                            <div className="mt-3 d-grid">
                                <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit" disabled={cartCourses.length === 0 ||
                                    totalAmountCalculationFunction() === 0}>Make Payment</button>
                            </div>

                            <input type="hidden" className="form-control" name="amount" id="amount" placeholder="Enter amount (ugx)" value={totalAmountCalculationFunction()} required="" min="500"/>


                        </form>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    );
}

export default App;
