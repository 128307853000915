import React, { useState } from "react";

function DonorsInfo(props) {

    const campaign_fields = props.data.campaign_fields;
    const genders = props.data.genders;
    const titles = props.data.titles;


    const [isAnonymous, setIsAnonymous] = useState(false);

    const showAno = campaign_fields.length > 0 //Hide or show the switch to ask for donor information

    const handleAnonymousCheckboxChange = (event) => {
        setIsAnonymous(event.target.checked);
    }

    function formatFieldName(fieldName) {
        // Remove underscores
        let formatted = fieldName.replace(/_/g, ' ');

        // Remove the word "display"
        formatted = formatted.replace(/display /i, '');

        // Capitalize the first word
        formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);

        return formatted;
    }

    const fieldOrder = [
        'display_title',
        'display_names',
        'display_gender',
        'display_phone_number',
        'display_account_number',
        'display_alternate_phone_number',
        'display_email_address',
        'display_physical_address',
        'display_postal_address',
        'display_organization',
    ];

    const arrangedFields = fieldOrder.filter(field => campaign_fields.includes(field));

    campaign_fields.sort((a, b) => {
        const indexA = arrangedFields.indexOf(a);
        const indexB = arrangedFields.indexOf(b);
        return indexA - indexB;
    });


    return (
        <>
            { showAno &&
            <div className="row mb-4 align-items-center">
                
                <div className="col">
                    <div className="form-check form-switch form-switch-lg mb-0" dir="ltr">
                        <input className="form-check-input" type="checkbox" id="anonymous" name="is_known" checked={isAnonymous} onChange={handleAnonymousCheckboxChange}/>
                        <label className="form-check-label" htmlFor="anonymous">Do you want us to know who you are?</label>
                    </div>
                </div>

            </div>
            }

            {isAnonymous &&
                <>
                    {campaign_fields.map((field) => (
                        <div className="mb-3" key={field}>
                            <label htmlFor={field} className="form-label">
                                {formatFieldName(field)}
                            </label>

                            {field === "display_gender" || field === "display_title" ? (
                                <select
                                    className="form-select"
                                    name={field}
                                    id={field}
                                    required
                                >
                                    <option value="">Select an option</option>
                                    {/* Replace the options below with your own values */}
                                    {field === "display_gender" ? (
                                        <>
                                            {genders.map((optionValue) => (
                                                <option key={optionValue[0]} value={optionValue[0]}>{optionValue[1]}</option>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {titles.map((optionValue) => (
                                                <option key={optionValue[0]} value={optionValue[0]}>{optionValue[1]}</option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    name={field}
                                    id={field}
                                    placeholder={`Enter ${formatFieldName(field)}`}
                                    required
                                />
                            )}

                        </div>
                    ))}
                </>

            }
        </>
    )
}

export default DonorsInfo;