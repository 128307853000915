import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { api_root } from "./api";
import $ from "jquery";


function SelectGovPayments(){

    function get_active_services(){
        const sessionData = sessionStorage.getItem('information_required');
        var response = null;
        if (sessionData) {
            const data = JSON.parse(sessionData);
            setServices(data.services);
        }else{
            Swal.fire({
                title: "Loading",
                html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                showConfirmButton: false,
                allowOutsideClick: !1
            })

            const fetchData = async () => {
                if(response){
                    return;
                }
                response = await fetch(api_root()+'/information_required/');
                const data = await response.json();
                sessionStorage.setItem('information_required', JSON.stringify(data));
                setServices(data.services);
            };
            fetchData();
        }
    }

    const [services, setServices] = useState([]);
    $(".back_button").hide()

    useEffect(() => {
        get_active_services()
    }, []);

    if(!services){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })
    }

    return (
        <>
            <Header />
            <div className="m-5">
                
                {services.includes("URA") && (
                    <div className="col">
                        <Link className="card bg-custom" to="/gov_payments?trans=ura">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Pay <br/>URA</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

                {services.includes("KCCA") && (
                    <div className="col">
                        <Link className="card bg-custom" to="/gov_payments?trans=kcca">
                            <div className="card-body">
                                <div className="d-flex text-center">
                                    <div className="flex-grow-1">
                                        <h4 className="mb-0 text-white">Pay <br/>KCCA</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )}

            </div>
            <Footer />
        </>
    )
}

export default SelectGovPayments;