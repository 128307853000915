import React from 'react';
import {api_root} from "./api";

function ShowProductComponent({ products, filterCourseFunction, addCourseToCartFunction }) {
    return (

        <>

            {filterCourseFunction.length === 0 ? (
                <p className="no-results">
                    Sorry, no available products.
                </p>
            ) : (
                filterCourseFunction.map((product) => (

                    <div className="col-4 product" key={product.id}>
                        <div className="card bg-custom mb-3">
                            <img src={api_root() + product.image} alt={product.name} className='img-fluid' style={{ maxHeight: 150 }}/>
                            <div className="card-body text-white text-center">
                                <h5 className="card-title text-white">{product.name}</h5>
                                <p className="card-text">{product.price} /= per bag</p>
                            </div>
                            <button type="button" className='btn btn-danger bg-custom btn-sm text-white' onClick={() => addCourseToCartFunction(product)}>&nbsp;<i className="fas fa-cart"></i> Add to Cart</button>
                        </div>
                    </div>
                ))
            )}

        </>
    );
}

export default ShowProductComponent;
