import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { api_root, page_defaults } from "./api";
import 'parsleyjs';

function Footer(){
    function CurrentYear() {
        return new Date().getFullYear();
    }

    // document.addEventListener('contextmenu', e => {
    //     e.preventDefault();
    // });

    const [data, setData] = useState(null);
    var response = null;

    $(document).ready(function() {
        // Wait for the document to be fully loaded
        // Then, select the elements with the "preloader" class,
        // add a delay of 350 milliseconds, and fade them out slowly
        $(".preloader").delay(100).fadeOut("slow");
    });


    useEffect(() => {
        page_defaults()
        // setData(JSON.parse(sessionStorage.getItem('information_required')));
        const fetchData = async () => {
            response = await fetch(api_root()+'/information_required/');
            if (!response) {
                return;
            }
            const data = await response.json();
            sessionStorage.setItem('information_required', JSON.stringify(data));
            setData(data);
            page_defaults()
        };
        // 1 minute
        const intervalId = setInterval(fetchData, 60000);

        // Cleanup the interval on component unmount
        // return () => clearInterval(intervalId);
    
        $("form").parsley()
        $("input[name=amount]").attr({min : 500})

    }, []);


    return (
        <div className="col-md-12 mt-4 text-center">

            <ul className="list-inline">
                <li className="list-inline-item">
                    <Link to="#" className="social-list-item bg-primary text-white border-primary">
                        <i className="mdi mdi-facebook fa-2x"></i>
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to="https://t.me/joinchat/QJWhnRPtvVc1K6L9dc3axQ" className="social-list-item bg-info text-white border-info">
                        <i className="mdi mdi-telegram fa-2x"></i>
                    </Link>
                </li>
                <li className="list-inline-item">
                    <Link to="https://wa.me/256393193345" className="social-list-item bg-success text-white border-success">
                        <i className="mdi mdi-whatsapp fa-2x"></i>
                    </Link>
                </li>
            </ul>
            <p>Powered by <Link to="https://blinkpay.co.ug/"><img src="https://www.1stop.co.ug/static/img/logo.jpg" style={{width:80}}/></Link></p>
            
            <p><Link to="https://www.blinkpay.co.ug/blink-systems-terms-and-conditions">Blinkpay Terms and Conditions</Link></p>
            <p>Copyright &copy; 2019 - <CurrentYear /> 1Stop.</p>
        </div>
    )
}

export default Footer;