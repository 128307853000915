import React, { useState, useEffect  } from "react";
import PaymentMethod from "./PaymentMethod";
import Header from "./Header";
import {api_root, check_status, sample_function} from './api';
import Footer from "./Footer";
import EventsInfo from "./EventsInfo";
import $ from "jquery";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import TransactionType from "./TransactionType";

function BuyTicket(){

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if(!searchParams || !searchParams.get('id')){
        window.location.href = '/events';
    }

    const event_id = parseInt(searchParams.get('id'));

    const [showEventsInfo, setShowEventsInfo] = useState(false);
    const [eventsInfoData, setEventsInfoData] = useState(null); // State to hold the response data
    const [hasError, setHasError] = useState(false); // State to track the error status

    function validate_event_id(event_id){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })

        $.ajax({
            method: 'GET',
            url: api_root()+'/validate_event_id/',
            dataType: 'json',
            data: {event_id:event_id},
            beforeSend: function() {
            },
            complete: function() {
            },

            success: function(json) {
                Swal.close();
                setEventsInfoData(json)
                setShowEventsInfo(true);
                setHasError(false);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                Swal.close();
                Swal.fire({
                    title: "Failed",
                    icon: "error",
                    html: 'Error Occured',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })
                setHasError(false);
                setShowEventsInfo(false);
                setEventsInfoData(null);

            }
        });
    }

    const contributeNowForm = (e) =>{

        e.preventDefault();

        $('textarea').removeAttr('disabled')

        $("input[name=transaction]").attr({'disabled':true})

        var form_data = $("#contribute_now_form").serialize();

        let output = '';
        const regex = /([^:]+):([^|]+)/g;

        sample_function().then(function(result) {
            result.replace(regex, (match, col1, col2) => {
                output += `<tr><td>${col1.replace(/\|\|/g, "").trim()}</td><td>${col2.trim()}</td></tr>`;

                var htmlMessage = `<div style="font-size: 14px !important;"> ${output} </div>`;

                Swal.fire({
                    icon: "question",
                    title: "Confirm Transaction",
                    html: `<table style="font-size: 14px !important; text-align: left;" width="100%">${htmlMessage}</table>`,
                    confirmButtonText: "Confirm",
                    confirmButtonColor: "#cc3432",
                    showDenyButton: true,
                    denyButtonText: 'No',
                    denyButtonColor: 'grey',
                    allowOutsideClick: !1,
                }).then((result) => {

                    if (result.isConfirmed) {

                        Swal.fire({
                            title: "Processing",
                            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
                            allowOutsideClick: !1,
                            showConfirmButton: false,
                        });

                        $.ajax({
                            method: "POST",
                            url: api_root()+"/Event_contribution/",
                            data : form_data,
                            success: function(res){
                                if (res.error){
                                    Swal.fire({
                                        icon: "error",
                                        title: "An Error Occurred",
                                        confirmButtonColor: "#cc3432",
                                        allowOutsideClick: !0
                                    })
                                    return
                                }

                                Swal.fire({
                                    html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/> Just a Moment',
                                    title: "Hold On",
                                    showConfirmButton: false,
                                    allowOutsideClick: !1
                                })

                                if(!res.reference){
                                    // redirect
                                    $(".visa_redirect").html(res.message)
                                }

                                if (res.reference){
                                    Swal.fire({
                                        html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div> <br/>'+res.message,
                                        title: "Please wait",
                                        showConfirmButton: false,
                                        allowOutsideClick: !1
                                    })
                                    check_status(res.reference[0], null, true, 3000)
                                }
                            }, error: function(){
                                Swal.close();
                                Swal.fire({
                                    icon: "error",
                                    title: "An Error Occurred",
                                    confirmButtonColor: "#cc3432",
                                    allowOutsideClick: !0
                                })
                            }
                        })

                        $('textarea').attr({'disabled':''})

                    }
                })
            })
        })

    }

    useEffect(() => {
        validate_event_id(event_id);
    }, [event_id]);  // Run this effect only when `event_id` changes

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        {showEventsInfo && !hasError && <EventsInfo event={eventsInfoData.event} packages={eventsInfoData.packages} />}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BuyTicket;