import React from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit } from './api';
import Header from "./Header";
import Footer from "./Footer";
import TransactionType from "./TransactionType";

function Enhas(){
    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">ENHAS Handling Payment</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                <TransactionType transaction="nas" />
                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_nas_reference">Payment Reference Number</label>
                                    <input type="text" name="nas_reference" title="" id="id_nas_reference" placeholder="Payment Reference Number" className="form-control" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">Amount (UGX)</label>
                                    <input type="number" className="form-control" name="amount" id="amount" placeholder="Enter amount (ugx)" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_consinee_name">Consignee Name</label>
                                    <input type="text" name="consinee_name" title="" id="id_consinee_name" placeholder="Consignee Name" className="form-control" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_consinee_phone_number">Consignee Phone Number</label>
                                    <input type="number" name="consinee_phone_number" title="" id="id_consinee_phone_number" placeholder="Consignee Phone Number" className="form-control" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_consinee_email">Consignee Email</label>
                                    <input type="email" name="consinee_email" title="" id="id_consinee_email" placeholder="Consignee Email" className="form-control" required/>
                                </div>

                                <PaymentMethod />

                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Enhas;
