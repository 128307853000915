import React from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit, api_root } from './api';
import Swal from 'sweetalert2';
import Header from "./Header";
import Footer from "./Footer";
import $ from 'jquery';
import TransactionType from './TransactionType'

function BuyData(){

    function get_bundle(recipient_msisdn){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })

        $.ajax({
            method: 'GET',
            url: api_root()+'/get_bundle/',
            dataType: 'json',
            data: {recipient_msisdn:recipient_msisdn},
            beforeSend: function() {
            },
            complete: function() {
            },

            success: function(json) {
                Swal.close();
                if(json.result === "error"){
                    Swal.close();
                    Swal.fire({
                        title: "No bundle Found",
                        html: '<div class="text-danger m-1"> <i class="fa fa-error-circle"></i> <br/> No Bundle Found </div>',
                        showConfirmButton: false,
                        allowOutsideClick: !0
                    })
                    return;
                }

                var option = '<option value="">----Select a Bundle----</option>';

                for (var i=0;i<json.result.length;i++){
                    option += '<option value="">-----' + json.result[i].category + '-----</option>';
                    for (var j=0;j<json.result[i].bundles.length;j++){
                            option += '<option value="'+ json.result[i].bundles[j].id + '|'+json.result[i].bundles[j].price+'">' + json.result[i].bundles[j].name + '@' + json.result[i].bundles[j].price + '/-</option>';
                    }
                }

                $('#id_data_package').html('<pre>'+option+'</pre>');
                
            },
            error: function(xhr, ajaxOptions, thrownError) {
                Swal.close();
                Swal.fire({
                    title: "Failed",
                    icon: "error",
                    html: 'Error Occured',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })

                $('#id_data_package').html('');
            }
        });
    }

    const handleMsisdnChange = (event) => {
        get_bundle($("#recipient_msisdn").val());
    };

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Buy Data Bundles</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                <TransactionType transaction="data" />
                                <div className="mb-3">
                                    <label htmlFor="recipient_msisdn" className="form-label">Receiving Number</label>
                                    <input type="number" className="form-control" name="recipient_msisdn" onBlur={handleMsisdnChange} id="recipient_msisdn" placeholder="Enter phone" required/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="id_data_package">Data Package</label>
                                    <select className="form-control" name="data_package" id="id_data_package" required>
                                        <option value="">Choose</option>
                                    </select>
                                </div>

                                <PaymentMethod />
                                
                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BuyData;