import React from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit } from './api';
import Header from "./Header";
import Footer from "./Footer";
import TransactionType from "./TransactionType";
import { useLocation } from "react-router-dom";

function Electricity(){

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if(!searchParams || !searchParams.get('id')){
        window.location.href = '/electricity';
    }

    const bill_item = parseInt(searchParams.get('id'));

    var electricity_name = "Electricity";
    if(bill_item==4372347){
        electricity_name = "Umeme Yaka";
    }else{
        electricity_name = "Umeme Postpaid";
    }

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Pay {electricity_name}</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                
                                <TransactionType transaction='electricity' />

                                <input type="hidden" name="bill_item" id="id_bill_item" value={bill_item} />

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_account">Account Number</label>
                                    <input type="text" name="account" title="" placeholder="Account Number" className="form-control" id="id_account" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_amount">Amount (UGX)</label>
                                    <input type="number" name="amount" title="" placeholder="Amount (ugx)" className="form-control" id="id_amount" required/>
                                </div>
                                

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_prn_phone_number">Customer Phone Number</label>
                                    <input type="number" name="prn_phone_number" title="" placeholder="Customer Phone Number" className="form-control" id="id_prn_phone_number" required/>
                                </div>

                                <div className="col">
                                        <div className="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                            <input className="form-check-input" type="checkbox" id="include_trans_charge" name="include_trans_charge" value={true}/>
                                            <label className="form-check-label" htmlFor="include_charge">Include Umeme charges to the amount</label>
                                        </div>
                                </div>

                                <PaymentMethod />
                                
                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Electricity;
