import React, { useEffect } from "react";
import PaymentMethod from "./PaymentMethod";
import { handleFormSubmit } from './api';
import Swal from 'sweetalert2';
import Header from "./Header";
import Footer from "./Footer";
import TransactionType from "./TransactionType";
import { api_root } from "./api";
import $ from 'jquery';

function Nwsc(){
    var xhr = null;

    function get_billing_item(){
        Swal.fire({
            title: "Loading",
            html: '<div class="spinner-border text-danger m-1" role="status"> <span class="sr-only">Loading...</span> </div>',
            showConfirmButton: false,
            allowOutsideClick: !1
        })

        if(xhr){
            return;
        }
        xhr = $.ajax({
            method: 'GET',
            url: api_root()+'/get_billing_item/?transaction=nwsc',
            dataType: 'json',

            success: function(json) {

                Swal.close();
                if(json.result === "error"){
                    Swal.fire({
                        title: "No Bill Items Found",
                        icon: "error",
                        html: '<div class="text-danger m-1"> <i class="fa fa-error-cirle"></i> <br/> No Billing Items </div>',
                        showConfirmButton: false,
                        allowOutsideClick: !0
                    })
                    return;
                }

                var option = '<option value="">----Select a Item----</option>';

                for (var i=0;i<json.length;i++){
                    option += '<option value="'+ json[i].id + '">' + json[i].name + '</option>';

                }

                $('#id_bill_item').html('<pre>'+option+'</pre>');

            },
            error: function(xhr, ajaxOptions, thrownError) {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    html: 'An error occured',
                    showConfirmButton: false,
                    allowOutsideClick: !0
                })
                $('#id_bill_item').html('');
            }
        });
    }

    useEffect(() => {
        get_billing_item();
    }, []);

    return (
        <>
            <Header />
            <div className="m-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title text-center">Pay NWSC</h4>
                        <div className="m-3">
                            <form className="form-horizontal" id="form_id_main" onSubmit={handleFormSubmit}>
                                <TransactionType transaction="nwsc" />
                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_account">Account Number</label><input type="number" name="account" title="" placeholder="Account Number" className="form-control" id="id_account" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_amount">Amount (UGX)</label>
                                    <input type="number" name="amount" title="" placeholder="Amount (ugx)" className="form-control" id="id_amount" required/>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_bill_item">Bill item</label>
                                    <select name="bill_item" id="id_bill_item" className="form-control" title="" required>
                                        <option value="">Choose</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label className="control-label" htmlFor="id_prn_phone_number">PRN Phone Number</label>
                                    <input type="number" name="prn_phone_number" title="" placeholder="Phone Number" className="form-control" id="id_prn_phone_number" required/>
                                </div>

                                <PaymentMethod />

                                <div className="mt-3 d-grid">
                                    <button className="btn btn-danger btn-custom waves-effect waves-light" type="submit">Process</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Nwsc;
