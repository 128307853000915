import toast from 'react-hot-toast';

// cartUtils.js
export function addToCart(item) {
    const existingCart = getCart();
    const existingItem = existingCart.find((cartItem) => cartItem.packageId === item.packageId);
    if (item.quantity < 1){
        return
    }
    if (existingItem) {
        // Item with the same package ID already exists, update its quantity and subtotal
        existingItem.quantity = item.quantity;
        toast.success("Your Cart has been modified", { duration: 3000 });
    } else {
        // Item doesn't exist in the cart, add it
        existingCart.push(item);
        toast.success("Added to cart", { duration: 3000 });
    }

    setCart(existingCart);
}

export function getCart() {
    return JSON.parse(sessionStorage.getItem("cart")) || [];
}

export function updateCartItem(item) {
    const updatedCart = getCart().map((cartItem) =>
        cartItem.packageId === item.packageId ? item : cartItem
    );
    setCart(updatedCart);
}

export function deleteCartItem(packageId) {
    const updatedCart = getCart().filter((cartItem) => cartItem.packageId !== packageId);
    setCart(updatedCart);
    toast.success("Item removed", { duration: 3000 });
}

export function setCart(cart) {
    sessionStorage.setItem("cart", JSON.stringify(cart));
}

// cartUtils.js
export function getCartItemQuantity(packageId) {
    const existingCart = getCart();
    const existingItem = existingCart.find((cartItem) => cartItem.packageId === packageId);
    return existingItem ? existingItem.quantity : null;
}

// cartUtils.js
export function isPackageInCart(packageId) {
    const existingCart = getCart();
    return existingCart.some((cartItem) => cartItem.packageId === packageId);
}

// cartUtils.js
export function getCartTotalAmount() {
    const existingCart = getCart();
    return existingCart.reduce((total, cartItem) => {
        return total + (cartItem.quantity * cartItem.package.amount);
    }, 0);
}

export function getCartTotalQuantity() {
    const existingCart = getCart();
    return existingCart.reduce((totalQuantity, cartItem) => {
        return totalQuantity + cartItem.quantity;
    }, 0);
}

export function clearCart() {
    sessionStorage.removeItem('cart');
    setCart(null)
    toast.success("Cart cleared", { duration: 3000 });
}