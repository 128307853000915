import React from "react";
import {Link} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Swal from "sweetalert2";
import CanGoBack from './CanGoBack'
function Header(){

    if (Swal.isVisible()) {
        Swal.close();
    }

    return(
        <>
            <div className="m-5 mt-0 mb-0">

                <Toaster />

                <nav className="navbar navbar-expand-md navbar-light">
                    <Link className="navbar-brand" to="/">
                        <img src="https://www.1stop.co.ug/static/img/logo.png" style={{height:60}} alt="logo" />
                    </Link>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <Link className="nav-link red-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link red-link" to="https://1stop.co.ug/login/">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link red-link" target="_blank" to="https://cars.1stop.co.ug">Cars</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link red-link" target="_blank" to="https://property.1stop.co.ug">Real Estate</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link red-link" to="/contact_us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div className="m-5 mt-0 mb-0"><div className="col-12" id="onestop_notifications"></div></div>
            <div className="visa_redirect"></div>

            <div className="text-center m--2"><CanGoBack /></div>
        </>
    )
}

export default Header;

// <li className="nav-item">
//     <Link className="nav-link red-link" target="_blank" to="https://shop.1stop.co.ug">Shop</Link>
// </li>